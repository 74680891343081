<template>
  <Columns v-if="project && !loading">
    <Column class="is-half">
      <h6 class="subtitle is-7 is-uppercase">project {{project.iid }} </h6>
      <h1 class="title is-2 is-size-1-widescreen is-spaced is-capitalized">{{ project.name }}</h1>
      <div class="block">
        <div class="tags has-addons">
          <span class="tag is-info has-text-white">Created</span>
          <span class="tag is-white">{{  $filters.convertDateTime(project.createdAt, 'MMMM D, YYYY')   }}</span>
        </div>
      </div>
      <div class="block">
        {{ project.description }}
      </div>
    </Column>
  </Columns>
</template>

<script>
  import { defineAsyncComponent } from "vue";
  import { getProjectInfoApi } from '@/use/useProject'

  export default {
    props: {
      fullPath: {
        type: String,
        required: true
      },
    },
    components: {
      Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
      Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    },

    setup(props) {

      const { project, loading } = getProjectInfoApi(props.fullPath);

      return {
        project,
        loading
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
